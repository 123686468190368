import React, { useContext } from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import Section from "../../../common/section"
import ProjectsSlider from "./projects-slider"
import { ProjectsContext } from "../../../../context/projects-color-context"
import Theme from "../../../../theme/theme"

const StyledSection = styled(Section)`
  margin-top: 10rem;
  margin-bottom: 10rem;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    margin-top: 30rem;
    margin-bottom: 30rem;
  }

  &:hover {
    .eye-cursor-container {
      display: block;
    }
  }
`

const PortfolioContent = ({ projects, projectsPageSlug, sectionTitle }) => {
  const { projectsColor } = useContext(ProjectsContext)

  return (
    <StyledSection
      pageColor={projectsColor}
      header={sectionTitle}
      textColor={Theme.colors.text}
      threshold={0.5}
    >
      <ProjectsSlider projects={projects} projectsPageSlug={projectsPageSlug} />
    </StyledSection>
  )
}

PortfolioContent.propTypes = {
  projects: PropTypes.array,
  projectsPageSlug: PropTypes.string,
  sectionTitle: PropTypes.string,
}

export default PortfolioContent
