import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import Section from "../../common/section"
import ScrollButton from "../../common/scroll-button"
import Shape from "../../svg/shape"
import { TRANSLATIONS } from "../../../utils/translations"
import Theme from "../../../theme/theme"
import { useInView } from "react-cool-inview"
import GatsbyImage from "../../common/gatsby-image"

const StyledSection = styled(Section)`
  position: relative;
  align-items: center;
  flex-direction: column;
  margin-bottom: 0;
  min-height: 100vh;

  @media (max-width: ${props => props.theme.devices.desktopLarge}) {
    min-height: 0;
    padding-top: 12rem !important;
  }
`

const StyledScrollButton = styled(ScrollButton)`
  max-width: 35rem;
  margin-left: auto;

  @media (max-width: ${props => props.theme.devices.desktop}) {
    margin: 0 auto;
  }

  @media (max-width: ${props => props.theme.devices.tablet}) {
    position: absolute;
    bottom: 1rem;
    padding: 0 5rem;
  }
`

const BgShape1 = styled(Shape)`
  position: absolute;
  top: 17%;
  left: 40%;
  transform: rotate(180deg);
  z-index: -1;
`
const BgShape2 = styled(Shape)`
  position: absolute;
  top: 41%;
  right: -1%;
  z-index: -1;
`

const BgShape3 = styled(Shape)`
  position: absolute;
  top: 59%;
  left: -4%;
  transform: rotate(-90deg);
  z-index: -1;
`

const BgShape4 = styled(Shape)`
  position: absolute;
  bottom: -12%;
  left: 41%;
  z-index: -1;
`

const H1 = styled.h1`
  font-size: 9vw;
  font-family: "darker_grotesquebold", sans-serif;
  line-height: 9vw;
  margin-bottom: 5rem;
  
  @media (max-width: ${props => props.theme.devices.mobile}) {
    text-align: center;
    margin-bottom: 18rem;
  }
  
  @media (min-width: ${props => props.theme.devices.mobile}) {
    .right {
      text-align: right;
    }
  }

  @media (min-width: ${props => props.theme.devices.desktop}) {
    ${props =>
      props.$inView &&
      `
    .word {
      top:0;
      opacity: 1;
    }
  `}
  }
}
`

const SingleWord = styled.span`
  display: inline-flex;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    position: relative;
    top: 4rem;
    opacity: 0;
    will-change: opacity, top;
    transition:
      opacity 1200ms ${props => props.theme.easing},
      top 1200ms ${props => props.theme.easing};
    transition-delay: ${props => props.index * 200}ms !important;
  }

  ${props =>
    props.$hasStroke &&
    `
    @supports (-webkit-text-stroke: 1px black) {
       color:transparent;
      -webkit-text-stroke-width: 0.1rem;
      -webkit-text-stroke-color: ${props.theme.colors.text};
    }
  `}

  @media (min-width: ${props => props.theme.devices.mobile}) {
    ${props =>
      props.index === 0 &&
      `
      margin-left: 8vw;
    `}

    ${props =>
      props.index === 4 &&
      `
      margin-left: auto;
    `}
  
    ${props =>
      props.index === 5 &&
      `
      line-height: 8vw;
    `}
  }
`

const StyledGatsbyImage = styled(GatsbyImage)`
  display: inline-flex;
  margin-left: 1rem;

  @media (min-width: ${props => props.theme.devices.desktopMedium}) {
    margin-left: 3rem;
  }

  > div > div {
    display: inline-flex;
  }

  .gatsby-image-wrapper {
    display: inline-block;

    @media (max-width: ${props => props.theme.devices.desktopMedium}) {
      width: 8rem !important;
      height: 8rem !important;
    }

    @media (max-width: ${props => props.theme.devices.mobile}) {
      display: block;
      width: 3rem !important;
      height: 3rem !important;
      margin: 0 auto;
      align-self: flex-end;
    }

    img {
      object-fit: contain !important;
    }
  }
`

const IntroSection = ({ homepageData }) => {
  const { observe, inView } = useInView()

  return (
    <StyledSection
      pageColor={Theme.colors.invertedText}
      textColor={Theme.colors.text}
      invertedTextColor={Theme.colors.invertedText}
      threshold={0.2}
      rootMargin="100px"
    >
      <BgShape1 isMainColor={true} delay={300} />
      <BgShape2 delay={500} />
      <BgShape3 />
      <BgShape4 type={2} delay={100} />
      <H1 ref={observe} $inView={inView}>
        <SingleWord index={0} className="word">
          We
        </SingleWord>{" "}
        <SingleWord index={1} $hasStroke={true} className="word">
          create
        </SingleWord>{" "}
        <SingleWord index={2} className="word">
          & support
        </SingleWord>
        <br />
        <SingleWord index={3} className="word">
          B2B & B2C
        </SingleWord>
        <br />
        <div className="right">
          <SingleWord index={4} className="word">
            eCommerce
          </SingleWord>
        </div>
        <div>
          <SingleWord index={5} $isPink={true} className="word">
            on <span>Prestashop</span>
            <StyledGatsbyImage
              image={homepageData.prestaLogo.gatsbyImageData}
              alt={homepageData.prestaLogo.title}
            />
          </SingleWord>
        </div>
      </H1>
      <StyledScrollButton sectionId="clients-section">
        {TRANSLATIONS[process.env.GATSBY_CONTENTFUL_LOCALE].whoWeWork}
      </StyledScrollButton>
    </StyledSection>
  )
}

IntroSection.propTypes = {
  homepageData: PropTypes.object,
}

export default IntroSection
