import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Section from "../../common/section"
import Shape from "../../svg/shape"
import DoubleShape from "../../svg/double-shape"
import ContentSlider from "../../common/content-slider"
import Theme from "../../../theme/theme"

const StyledSection = styled(Section)`
  position: relative;
  margin-top: 30rem;
  margin-bottom: 30rem;
`

const StyledShapeBg = styled(Shape)`
  position: absolute;
  top: 50%;
  right: -40%;
  width: 100%;
  max-width: initial;
  z-index: -5;
  transform: translate3d(0, -55%, 0) matrix(0.99, 0.12, -0.12, 0.99, 0, 0);
`

const ReviewsSection = ({ projects }) => {
  const filteredProjects = projects.filter(
    project => project.review?.content.length > 0,
  )

  return (
    filteredProjects.length > 0 && (
      <StyledSection pageColor={Theme.colors.invertedText} threshold={0.5}>
        <ContentSlider items={filteredProjects} isReviews={true} />
        <DoubleShape isAnimated={true} />
        <StyledShapeBg type={6} threshold={0.2} />
      </StyledSection>
    )
  )
}

ReviewsSection.propTypes = {
  projects: PropTypes.array,
}

export default ReviewsSection
