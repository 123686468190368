import React, { useContext, useEffect, useRef, useState } from "react"
import { useMediaQuery } from "react-responsive"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import Section from "../../common/section"
import SingleStat from "./numbers/single-stat"
import Shape from "../../svg/shape"
import Theme from "../../../theme/theme"
import { MainContext } from "../../../context/main-context"
import { useInView } from "react-cool-inview"
import devices from "../../../theme/devices"

const StyledSection = styled(Section)`
  padding-bottom: 0;
  margin-bottom: 0;
`

const NumbersSlider = styled.div`
  position: relative;
  min-height: 80vh;
`

const Container = styled.div`
  position: relative;
`

const NumbersSection = ({ homepageData = {} }) => {
  const ref = useRef()
  const [activeIndex, setActiveIndex] = useState(0)
  const headerContent = documentToReactComponents(
    JSON.parse(homepageData.numbers.raw),
  )
  const numbersHeader = headerContent.filter(node => node.type === "h2")[0]
  const numbersList = headerContent.filter(node => node.type === "ul")[0]
  const childrenNumber = numbersList.props.children.length
  const isDesktopOrLaptop = useMediaQuery({
    query: `(min-width: ${devices.tablet})`,
  })

  const options = {
    renderNode: {
      [BLOCKS.HEADING_2]: () => {
        return
      },
      [BLOCKS.UL_LIST]: (node, children) => {
        if (!children) return

        return (
          <NumbersSlider>
            {children.map((child, index) => (
              <SingleStat key={index} isActive={index === activeIndex}>
                {child.props.children}
              </SingleStat>
            ))}
          </NumbersSlider>
        )
      },
    },
    renderMark: {
      [MARKS.BOLD]: text => (
        <strong>
          {text}
          <Shape trackVisibility={true} />
        </strong>
      ),
    },
  }

  useEffect(() => {
    if (!childrenNumber || !ref || !isDesktopOrLaptop) return

    const step = 100 / childrenNumber

    const st = ScrollTrigger.create({
      trigger: ".trigger",
      start: "-80px top",
      end: `+=${ref.current.clientHeight * childrenNumber}`,
      pin: true,
      scrub: true,
      onUpdate(data) {
        const progress = data.progress * 100

        setActiveIndex(
          progress === 100 ? childrenNumber - 1 : Math.floor(progress / step),
        )
      },
    })

    return () => {
      st.kill()
    }
  }, [ref, childrenNumber, isDesktopOrLaptop])

  const { setPageColor } = useContext(MainContext)
  const { observe, inView } = useInView({
    threshold: 0.5,
  })

  useEffect(() => {
    if (inView) {
      setPageColor(Theme.colors.fourth)
    }
  }, [inView, setPageColor])

  return (
    <StyledSection
      pageColor={Theme.colors.fourth}
      textColor={Theme.colors.invertedText}
      invertedTextColor={inView ? Theme.colors.text : Theme.colors.invertedText}
      header={numbersHeader.props.children[0]}
    >
      <div ref={ref}>
        <Container className="trigger" activeIndex={activeIndex} ref={observe}>
          {documentToReactComponents(
            JSON.parse(homepageData.numbers.raw),
            options,
          )}
        </Container>
      </div>
    </StyledSection>
  )
}

NumbersSection.propTypes = {
  homepageData: PropTypes.object,
}

export default NumbersSection
