import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { TRANSLATIONS } from "../../../utils/translations"
import Section from "../../common/section"
import Link from "../../common/link"
import SectionHeader from "../../common/section-header"
import Shape from "../../svg/shape"
import Theme from "../../../theme/theme"

const StyledSectionHeader = styled(SectionHeader)`
  max-width: 93rem;
  margin-bottom: 3rem;

  @media (min-width: ${props => props.theme.devices.desktopMedium}) {
    margin-bottom: 13rem;
  }
`

const SectionContent = styled.div`
  position: relative;
  margin-bottom: 4rem;

  @media (max-width: ${props => props.theme.devices.desktopMedium}) {
    display: flex;
    flex-direction: column;
  }

  @media (min-width: ${props => props.theme.devices.desktop}) {
    margin-bottom: 20rem;
  }
`

const SectionExtraInfo = styled.p`
  font-size: 2.6rem;
  margin-bottom: 7rem;

  @media (min-width: ${props => props.theme.devices.desktopMedium}) {
    position: absolute;
    top: 0.5rem;
    right: 0;
    max-width: 36rem;
    font-size: 3.6rem;
    margin-bottom: 0;
  }
`

const StyledShape = styled(Shape)`
  position: absolute;
  top: -15rem;
  left: -8%;
  max-width: initial;
  z-index: -1;
`

const ContactSection = ({ homepageData, contactPageSlug }) => {
  const contactContent = documentToReactComponents(
    JSON.parse(homepageData.contact.raw),
  )
  const contactHeader = contactContent.filter(node => node.type === "h2")[0]
  const contactText = contactContent.filter(node => node.type === "p")[0]

  return (
    <Section pageColor={Theme.colors.invertedText}>
      <SectionContent>
        <StyledSectionHeader isBolded={false}>
          {contactHeader.props.children}
        </StyledSectionHeader>
        <SectionExtraInfo>{contactText.props.children}</SectionExtraInfo>
        <Link url={`/${contactPageSlug}/`} isButton={true}>
          {TRANSLATIONS[process.env.GATSBY_CONTENTFUL_LOCALE].contactUs}
        </Link>
        <StyledShape type={3} animationTime={1400} />
      </SectionContent>
    </Section>
  )
}

ContactSection.propTypes = {
  homepageData: PropTypes.object,
  contactPageSlug: PropTypes.string,
}

export default ContactSection
