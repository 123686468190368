import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import Section from "../../common/section"
import ClientsContainer from "../../common/clients/clients-container"
import Theme from "../../../theme/theme"

const StyledSection = styled(Section)`
  margin-bottom: 0;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    padding-top: 10rem;
    padding-bottom: 12rem;
  }
`

const ClientsSection = ({ header, projects, projectsSlug }) => {
  return (
    <StyledSection
      pageColor={Theme.colors.invertedText}
      textColor={Theme.colors.text}
      invertedTextColor={Theme.colors.invertedText}
      invertedBgColor={Theme.colors.invertedText}
      header={header}
      threshold={0.3}
      sectionId="clients-section"
    >
      <ClientsContainer projects={projects} projectsSlug={projectsSlug} />
    </StyledSection>
  )
}

ClientsSection.propTypes = {
  projects: PropTypes.array,
  header: PropTypes.string,
  projectsSlug: PropTypes.string,
}

export default ClientsSection
