import React from "react"
import PropTypes from "prop-types"
import ProjectsColorProvider from "../../../context/projects-color-context"
import PortfolioContent from "./portfolio/content"

const PortfolioSection = ({ projects, projectsPageSlug, homepageData }) => {
  if (!projects) return

  return (
    <ProjectsColorProvider>
      <PortfolioContent
        sectionTitle={homepageData.title}
        projects={projects}
        projectsPageSlug={projectsPageSlug}
      />
    </ProjectsColorProvider>
  )
}

PortfolioSection.propTypes = {
  projects: PropTypes.array,
  projectsPageSlug: PropTypes.string,
  homepageData: PropTypes.object,
}

export default PortfolioSection
