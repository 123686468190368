import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import devices from "../../../../theme/devices"
import { useMediaQuery } from "react-responsive"
import SingleProject from "./single-project"
import { ProjectsContext } from "../../../../context/projects-color-context"
import Link from "../../../common/link"
import ArrowSlider from "../../../svg/arrow-slider"
import { TRANSLATIONS } from "../../../../utils/translations"
import DesktopSlider from "./desktop-slider"
import MobileSlider from "./mobile-slider"

const ProjectsSliderElement = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 7rem;

  @media (min-width: ${props => props.theme.devices.desktopMedium}) {
    margin-top: 10rem;
  }
`

const ProjectsSliderWrapper = styled.div`
  position: relative;
  width: 54rem;
  max-width: 100%;
`

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 5rem;
  width: 10rem;
  height: 10rem;
  background-color: ${props => props.theme.colors.text};
  color: ${props => props.theme.colors.invertedText};
  font-size: 3rem;
  font-family: "darker_grotesquebold", sans-serif;
  border-radius: 50%;

  @media (min-width: ${props => props.theme.devices.desktopLarge}) {
    right: 0;
    width: 15rem;
    height: 15rem;
  }
`

const ProjectLink = styled(Link)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
`

const SliderStatus = styled.div`
  position: relative;
  top: -0.4rem;
`

const NavigationActions = styled.div`
  display: flex;
  align-items: center;
  width: 54rem;
  max-width: 100%;
  margin: 0 auto;
`

const AllLink = styled(Link)`
  position: relative;
  color: inherit;
  font-size: 2.4rem;
  line-height: 2.4rem;
  font-family: "darker_grotesquebold", sans-serif;

  &:after {
    position: absolute;
    left: 0;
    bottom: -1rem;
    height: 0.1rem;
    width: 100%;
    border-bottom: 0.1rem solid;
    border-color: inherit;
    content: "";
  }
`

const ButtonsContainer = styled.div`
  margin-left: auto;
`

const SliderButton = styled.button`
  background: none;
  border: 0;
  cursor: pointer;

  ${props =>
    props.$isNext &&
    `
    margin-left: 5rem;
    
    svg {
      transform: scaleX(-1);
    }
  `}
`

const ProjectsSlider = ({ projects, projectsPageSlug }) => {
  const [activeSlide, setActiveSlide] = useState(0)
  const { setProjectsColor } = useContext(ProjectsContext)

  const isDesktopOrLaptop = useMediaQuery({
    query: `(min-width: ${devices.tablet})`,
  })

  useEffect(() => {
    setProjectsColor(projects[activeSlide].mainColor)
  }, [projects, activeSlide, setProjectsColor])

  if (!projects) return

  const changeSlide = isNext => {
    if (isNext) {
      if (activeSlide === projects.length - 1) {
        setActiveSlide(0)
      } else {
        setActiveSlide(activeSlide + 1)
      }
    } else {
      if (activeSlide === 0) {
        setActiveSlide(projects.length - 1)
      } else {
        setActiveSlide(activeSlide - 1)
      }
    }
  }

  return (
    <>
      <ProjectsSliderElement>
        <ProjectsSliderWrapper>
          {projects.map((project, index) => (
            <SingleProject
              key={project.contentful_id}
              project={project}
              isActive={activeSlide === index}
            />
          ))}
          <StatusContainer>
            <SliderStatus>
              <span>
                {activeSlide + 1}/{projects.length}
              </span>
            </SliderStatus>
          </StatusContainer>
        </ProjectsSliderWrapper>
        {isDesktopOrLaptop ? (
          <DesktopSlider projects={projects} activeSlide={activeSlide} />
        ) : (
          <MobileSlider projects={projects} activeSlide={activeSlide} />
        )}

        <ProjectLink
          url={`/${projectsPageSlug}/${projects[activeSlide].slug}/`}
          ariaLabel={projects[activeSlide].title}
        />
      </ProjectsSliderElement>
      <NavigationActions>
        <AllLink url={`/${projectsPageSlug}/`}>
          {TRANSLATIONS[process.env.GATSBY_CONTENTFUL_LOCALE].allProjects}
        </AllLink>
        <ButtonsContainer>
          <SliderButton
            onClick={() => {
              changeSlide()
            }}
            aria-label={
              TRANSLATIONS[process.env.GATSBY_CONTENTFUL_LOCALE].prevProject
            }
          >
            <ArrowSlider />
          </SliderButton>
          <SliderButton
            $isNext={true}
            onClick={() => {
              changeSlide(true)
            }}
            aria-label={
              TRANSLATIONS[process.env.GATSBY_CONTENTFUL_LOCALE].nextProject
            }
          >
            <ArrowSlider />
          </SliderButton>
        </ButtonsContainer>
      </NavigationActions>
    </>
  )
}

ProjectsSlider.propTypes = {
  projects: PropTypes.array,
}

export default ProjectsSlider
