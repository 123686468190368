import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { gsap } from "gsap"

const InfiniteSlideContainer = styled.div``

const InfiniteSlideInner = styled.div`
  display: flex;
  flex-wrap: nowrap;

  > * {
    min-width: ${props => props.childWidth}px;
  }
`

const InfiniteSlide = ({
  children,
  className,
  slideDuration = 100,
  isReversed = false,
  divider = 1,
  refreshIndex = 0,
}) => {
  const [childWidth, setChildWidth] = useState()
  const ref = useRef()

  useEffect(() => {
    if (!slideDuration || !divider) return

    const widthAttribute = ref.current.children[0].getAttribute("width")

    const width = widthAttribute
      ? widthAttribute
      : ref.current.children[0].clientWidth

    setChildWidth(width)

    const slide = gsap.timeline({ repeat: -1 })
    slide.to(ref.current, {
      x: ((isReversed ? 1 : -1) * width) / divider - 3,
      ease: "linear",
      duration: slideDuration,
    })
  }, [slideDuration, divider, isReversed])

  return (
    <InfiniteSlideContainer className={className}>
      <InfiniteSlideInner ref={ref} childWidth={childWidth}>
        {children}
      </InfiniteSlideInner>
    </InfiniteSlideContainer>
  )
}

InfiniteSlide.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  className: PropTypes.string,
  isReversed: PropTypes.bool,
  divider: PropTypes.number,
}

export default InfiniteSlide
