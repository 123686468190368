import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import InfiniteSlide from "../../../common/infinite-slide"

const StyledInfiniteSlide = styled(InfiniteSlide)`
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  font-size: 30rem;
  font-family: "darker_grotesquebold", sans-serif;

  > div {
    position: relative;
    top: -4rem;

    > div {
      white-space: nowrap;
      padding-right: 20rem;
    }
  }
`

const DesktopSlider = ({ projects, activeSlide }) => {
  return (
    <>
      <StyledInfiniteSlide slideDuration={10} refreshIndex={activeSlide}>
        <div>{projects[activeSlide].title}</div>
        <div>{projects[activeSlide].title}</div>
        <div>{projects[activeSlide].title}</div>
      </StyledInfiniteSlide>
    </>
  )
}

DesktopSlider.propTypes = {
  projects: PropTypes.array,
  activeSlide: PropTypes.number,
}

export default DesktopSlider
