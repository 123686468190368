import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import { TRANSLATIONS } from "../../../utils/translations"
import Section from "../../common/section"
import SectionHeader from "../../common/section-header"
import Link from "../../common/link"
import Theme from "../../../theme/theme"
import { useMediaQuery } from "react-responsive"
import devices from "../../../theme/devices"
import BlogAnimations from "./blog/animations"
import PostsList from "../../common/blog/posts-list"

const StyledSection = styled(Section)`
  position: relative;
  justify-content: flex-start;
  padding-bottom: 15rem;
`

const StyledSectionHeader = styled(SectionHeader)`
  position: relative;
  margin-bottom: 7rem;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    padding-right: 20rem;
    margin-bottom: 14rem;
  }
`

const StyledLink = styled(Link)`
  display: block;
  font-size: 2.4rem;
  line-height: 2.4rem;
  font-family: "darker_grotesquebold", sans-serif;
  text-align: right;
  margin-top: 5rem;
  margin-left: auto;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    position: absolute;
    top: 50%;
    right: 0;
    text-align: left;
    margin-top: -1.7rem;

    &:after {
      position: absolute;
      left: 0;
      bottom: -1rem;
      height: 0.1rem;
      width: 100%;
      background: ${props => props.theme.colors.text};
      content: "";
    }
  }
`

const BlogSection = ({ blogPageSlug, posts, homepageData }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: `(min-width: ${devices.tablet})`,
  })

  if (!posts || posts.length === 0) return

  return (
    <StyledSection pageColor={Theme.colors.invertedText} $isLast={true}>
      <StyledSectionHeader>
        {homepageData.blogTitle}
        <StyledLink url={`/${blogPageSlug}/`}>
          {TRANSLATIONS[process.env.GATSBY_CONTENTFUL_LOCALE].allPosts}
        </StyledLink>
      </StyledSectionHeader>
      <PostsList posts={posts} blogPageSlug={blogPageSlug} />
      {isDesktopOrLaptop && <BlogAnimations />}
    </StyledSection>
  )
}

BlogSection.propTypes = {
  homepageData: PropTypes.object,
  blogPageSlug: PropTypes.string,
  posts: PropTypes.array,
}

export default BlogSection
