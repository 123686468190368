import React, { createContext, useState } from "react"

const ProjectsContext = createContext()

const ProjectsColorProvider = ({ children }) => {
  const [projectsColor, setProjectsColor] = useState()

  return (
    <ProjectsContext.Provider value={{ projectsColor, setProjectsColor }}>
      {children}
    </ProjectsContext.Provider>
  )
}

export { ProjectsContext }

export default ProjectsColorProvider
