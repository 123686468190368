import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Theme from "../../../theme/theme"
import Section from "../../common/section"
import Content from "./about/content"
import Shape from "../../svg/shape"
import GatsbyImage from "../../common/gatsby-image"

const StyledSection = styled(Section)`
  position: relative;
  padding-bottom: 10rem;
  margin-bottom: 0;

  @media (min-width: ${props => props.theme.devices.desktopMedium}) {
    padding-bottom: 50rem;
    padding-top: 10rem;
    margin-bottom: 10rem;
  }
`

const StyledShape2Bottom = styled(Shape)`
  position: absolute;
  bottom: 3%;
  left: 0;
  max-width: 40rem;
  transform: rotate(180deg);
  z-index: -2;

  @media (min-width: ${props => props.theme.devices.desktopMedium}) {
    max-width: 100%;
  }
`

const StyledShape1Left = styled(Shape)`
  display: none;

  @media (min-width: ${props => props.theme.devices.desktopMedium}) {
    display: block;
    position: absolute;
    top: 16%;
    left: 0;
    transform: rotate(-180deg);
    z-index: -2;
  }
`

const StyledShape1Right = styled(Shape)`
  position: absolute;
  top: 36.3%;
  right: 12rem;
  transform: rotate(-90deg);
  z-index: -2;
`

const StyledShape1Bottom = styled(Shape)`
  position: absolute;
  bottom: 1%;
  left: 62%;
  transform: rotate(90deg);
`

const StyledGatsbyImage1 = styled(GatsbyImage)`
  > div > div {
    transform: translate3d(0, 0, 0) !important;
  }
  margin-bottom: 5rem;

  @media (min-width: ${props => props.theme.devices.desktopMedium}) {
    position: absolute;
    top: 8%;
    right: 0;
    width: 50rem;
    z-index: -1;
  }
`

const StyledGatsbyImage2 = styled(GatsbyImage)`
  display: none;

  @media (min-width: ${props => props.theme.devices.desktopMedium}) {
    display: block;
    position: absolute;
    top: 28%;
    left: -10rem;
    width: 44rem;
    z-index: -1;
  }
`

const StyledGatsbyImage3 = styled(GatsbyImage)`
  display: none;

  @media (min-width: ${props => props.theme.devices.desktopMedium}) {
    display: block;
    position: absolute;
    top: 45%;
    right: 18%;
    width: 39rem;
    z-index: -1;
  }
`

const AboutSection = ({ aboutUsPageSlug, homepageData }) => {
  return (
    <StyledSection
      pageColor={Theme.colors.text}
      textColor={Theme.colors.invertedText}
      threshold={0.2}
    >
      <StyledShape2Bottom isMainColor={true} type={2} />
      <StyledShape1Bottom isThirdColor={true} />
      <StyledShape1Left />
      <StyledShape1Right isMainColor={true} />
      <StyledGatsbyImage1
        image={homepageData.aboutUsImages[0].gatsbyImageData}
        hasBottomLeftRadius={true}
        isParallax={true}
      />
      <StyledGatsbyImage2
        image={homepageData.aboutUsImages[1].gatsbyImageData}
        hasBottomRightRadius={true}
        isParallax={true}
      />
      <StyledGatsbyImage3
        image={homepageData.aboutUsImages[2].gatsbyImageData}
        hasTopRightRadius={true}
        isParallax={true}
      />
      <Content aboutUsPageSlug={aboutUsPageSlug} homepageData={homepageData} />
    </StyledSection>
  )
}

AboutSection.propTypes = {
  homepageData: PropTypes.object,
  aboutUsPageSlug: PropTypes.string,
}

export default AboutSection
