import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"

const StyledStaticSlide = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  white-space: nowrap;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  font-size: 30rem;
  font-family: "darker_grotesquebold", sans-serif;

  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
`

const DesktopSlider = ({ projects, activeSlide }) => {
  return (
    <StyledStaticSlide>
      <div>{projects[activeSlide].title}</div>
    </StyledStaticSlide>
  )
}

DesktopSlider.propTypes = {
  projects: PropTypes.array,
  activeSlide: PropTypes.number,
}

export default DesktopSlider
