import React from "react"
import Viewport from "../../components/common/viewport"
import SEO from "../../components/seo"
import IntroSection from "../../components/content/homepage/intro-section"
import AboutSection from "../../components/content/homepage/about-section"
import NumbersSection from "../../components/content/homepage/numbers-section"
import ClientsSection from "../../components/content/homepage/clients-section"
import PortfolioSection from "../../components/content/homepage/portfolio-section"
import ReviewsSection from "../../components/content/homepage/reviews-section"
import ContactSection from "../../components/content/homepage/contact-section"
import BlogSection from "../../components/content/homepage/blog-section"

const IndexPage = props => {
  const page = props.pageContext.homepageData

  return (
    <Viewport>
      <IntroSection homepageData={page} />
      <ClientsSection
        header={page.clientsTitle}
        projects={props.pageContext.projects}
        projectsSlug={props.pageContext.projectsPageSlug}
      />
      <AboutSection
        homepageData={page}
        aboutUsPageSlug={props.pageContext.aboutUsPageSlug}
      />
      <NumbersSection homepageData={page} />
      <PortfolioSection
        homepageData={page}
        projects={page.projects}
        projectsPageSlug={props.pageContext.projectsPageSlug}
      />
      <ReviewsSection projects={props.pageContext.projects} />
      <ContactSection
        homepageData={page}
        contactPageSlug={props.pageContext.contactPageSlug}
      />
      {process.env.GATSBY_CONTENTFUL_LOCALE === "pl" && (
        <BlogSection
          homepageData={page}
          posts={page.posts}
          blogPageSlug={props.pageContext.blogPageSlug}
        />
      )}
    </Viewport>
  )
}

export default IndexPage

export const Head = ({ location, pageContext }) => (
  <SEO
    pathname={location.pathname}
    title={pageContext.homepageData.seoTitle}
    description={pageContext.homepageData.seoDescription}
    keywords={pageContext.homepageData.seoKeywords}
  />
)
