import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import GatsbyImage from "../../../common/gatsby-image"

const SingleProjectElement = styled.article`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition:
    opacity 800ms ${props => props.theme.easing},
    top 800ms ${props => props.theme.easing};

  .project-title,
  .project-image {
    top: 3rem;
    opacity: 0;
    transition:
      opacity 800ms ${props => props.theme.easing} 0ms,
      top 800ms ${props => props.theme.easing} 0ms;
  }

  .project-image {
    position: relative;
    min-height: 100%;

    .gatsby-image-wrapper {
      min-height: 100%;
    }
  }

  ${props =>
    props.$isActive &&
    `
      position: relative;
      width: 54rem;
      max-width: 100%;
      opacity: 1;
    
      .project-title,
      .project-image{
        top: 0;
        opacity: 1;
      }
      
      .project-image{
        transition-delay: 200ms;
      }   
  `}
`

const SingleProjectContent = styled.div`
  overflow: hidden;
  width: 44rem;
  max-width: 100%;
  height: 58rem;
  margin: 0 auto;
  border-bottom-right-radius: 8rem;
  transform: translateZ(0);

  @media (min-width: ${props => props.theme.devices.desktopLarge}) {
    width: 54rem;
    height: 68rem;
  }
`

const StyledGatsbyImage = styled(GatsbyImage)`
  overflow: hidden;
  width: 44rem;
  height: 58rem;
  max-width: 100%;
  margin: 0 auto;
  border-bottom-right-radius: 8rem;
  transform: translateZ(0);

  @media (min-width: ${props => props.theme.devices.desktopLarge}) {
    width: 54rem;
    height: 68rem;
  }
`

const ProjectTitle = styled.div`
  margin-bottom: 2rem;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    position: absolute;
    left: -6rem;
    top: 0;
    margin-bottom: 0;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
  }
`

const SingleProject = ({ project, isActive }) => {
  if (!project) return

  return (
    <SingleProjectElement $isActive={isActive}>
      <SingleProjectContent>
        <ProjectTitle className="project-title">{project.title}</ProjectTitle>
        {project?.mainImage && (
          <StyledGatsbyImage
            image={project.mainImage.gatsbyImageData}
            extraClass="project-image"
          />
        )}
      </SingleProjectContent>
    </SingleProjectElement>
  )
}

SingleProject.propTypes = {
  project: PropTypes.object,
}

export default SingleProject
