import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

const SingleStatElement = styled.div`
  max-width: 150rem;
  width: 100%;
  font-size: 4rem;
  line-height: 6.5rem;
  font-family: "darker_grotesquebold", sans-serif;
  text-align: center;
  margin: 10rem auto;

  transition:
    opacity 600ms ${props => props.theme.easing},
    top 600ms ${props => props.theme.easing};

  @media (min-width: ${props => props.theme.devices.desktop}) {
    position: absolute;
    top: 55%;
    left: 50%;
    font-size: 8vw;
    line-height: 9vw;
    margin: 0 auto;
    transform: translate3d(-50%, -50%, 0);
    opacity: 0;

    ${props =>
      props.$isActive &&
      `
    opacity: 1;
    top: 50%;
  `}
  }

  strong {
    display: inline-block;
    position: relative;
    top: 3rem;
    font-size: 14rem;
    line-height: 0;

    @media (min-width: ${props => props.theme.devices.desktop}) {
      font-size: 18vw;
      line-height: 12vw;
    }

    @media (min-width: ${props => props.theme.devices.desktopLarge}) {
      top: 7rem;
      font-size: 21vw;
      line-height: 6vw;
    }

    .shape-container {
      display: none;

      ${props =>
        props.$isActive &&
        `
          display: block;
        `}

      position: absolute;
      left: 50%;
      bottom: 2rem;
      min-width: 20rem;
      transform: translate3d(-50%, 0, 0) rotate(-90deg);
      z-index: -1;

      path {
        stroke: ${props => props.theme.colors.main};
      }

      @media (min-width: ${props => props.theme.devices.desktopLarge}) {
        min-width: 30rem;
        bottom: -8rem;
      }
    }
  }
`

const SingleStat = ({ children, isActive }) => {
  return (
    <SingleStatElement className="single-stat" $isActive={isActive}>
      {children}
    </SingleStatElement>
  )
}

SingleStat.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  isActive: PropTypes.bool,
}

export default SingleStat
