import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { TRANSLATIONS } from "../../../../utils/translations"
import SectionHeader from "../../../common/section-header"
import ColumnHeader from "../../../common/column-header"
import ShowContainer from "../../../common/show-animation"
import Link from "../../../common/link"
import Arrow from "../../../svg/arrow"

const ContentElement = styled.div`
  @media (min-width: ${props => props.theme.devices.desktop}) {
    max-width: 50%;
  }

  @media (min-width: ${props => props.theme.devices.desktopMedium}) {
    max-width: 74rem;
    margin: 0 auto;
  }
`

const ContentDescription = styled.div`
  font-size: 2.6rem;
  line-height: 3.8rem;
  margin: 3rem 0 8rem;

  @media (min-width: ${props => props.theme.devices.desktopLarge}) {
    font-size: 3.6rem;
    line-height: 4.8rem;
  }

  @media (min-width: ${props => props.theme.devices.desktopMedium}) {
    margin: 3rem 0 16rem;
  }
`

const AboutList = styled.ul`
  color: ${props => props.theme.colors.invertedText};
  list-style-type: none;
  font-size: 4rem;
  line-height: 4.5rem;
  margin: 0;
  padding: 0;

  @media (min-width: ${props => props.theme.devices.desktopMedium}) {
    font-size: 6rem;
    line-height: 8rem;
  }

  @media (min-width: ${props => props.theme.devices.desktopLarge}) {
    font-size: 10rem;
    line-height: 14rem;
  }

  li {
    position: relative;
    padding-left: 4rem;

    @media (min-width: ${props => props.theme.devices.desktopMedium}) {
      padding-left: 7rem;
    }

    a {
      color: ${props => props.theme.colors.invertedText};
    }

    svg {
      position: absolute;
      top: 1rem;
      left: 0;
      max-width: 2rem;
      transform: rotate(90deg);

      @media (min-width: ${props => props.theme.devices.desktopMedium}) {
        top: 3.5rem;
        max-width: 80%;
      }

      @media (min-width: ${props => props.theme.devices.desktopLarge}) {
        top: 6.5rem;
        max-width: 100%;
      }
    }
  }
`

const MoreContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 8rem 0 6rem;

  div {
    max-width: 45rem;
    flex: 1;
    border-top: 0.2rem solid ${props => props.theme.colors.invertedText};
    margin-top: 0.2rem;
  }

  p {
    margin-left: 5rem;
  }
`

const AboutContent = ({ aboutUsPageSlug, homepageData }) => {
  const options = {
    renderNode: {
      [BLOCKS.UL_LIST]: (node, children) => {
        if (!children) return

        return (
          <AboutList>
            {children.map((child, index) => (
              <li key={index}>
                <Arrow />
                {child.props.children}
              </li>
            ))}
          </AboutList>
        )
      },
      [INLINES.ENTRY_HYPERLINK]: ({ data }, children) => {
        const link = homepageData.aboutUsList.references.find(
          reference => reference.contentful_id === data.target.sys.id,
        )

        return <Link url={`/${link.slug}`}>{children[0]}</Link>
      },
    },
  }

  return (
    <ContentElement>
      <ShowContainer>
        <SectionHeader>{homepageData.aboutUsHeader}</SectionHeader>
        <ContentDescription>
          {documentToReactComponents(
            JSON.parse(homepageData.aboutUsContent.raw),
          )}
        </ContentDescription>
      </ShowContainer>
      <ShowContainer>
        <ColumnHeader>{homepageData.aboutUsColumn}</ColumnHeader>
        {documentToReactComponents(
          JSON.parse(homepageData.aboutUsList.raw),
          options,
        )}
        <MoreContainer>
          <div />
          <ColumnHeader>
            {TRANSLATIONS[process.env.GATSBY_CONTENTFUL_LOCALE].andMore}
          </ColumnHeader>
        </MoreContainer>
        <Link url={`/${aboutUsPageSlug}/`} isButton={true}>
          {TRANSLATIONS[process.env.GATSBY_CONTENTFUL_LOCALE].moreAboutUs}
        </Link>
      </ShowContainer>
    </ContentElement>
  )
}

AboutContent.propTypes = {
  homepageData: PropTypes.object,
  aboutUsPageSlug: PropTypes.string,
}

export default AboutContent
