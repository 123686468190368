import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Blog from "../../../svg/blog"
import Theme from "../../../../theme/theme"
import InfiniteSlide from "../../../common/infinite-slide"
import DoubleShape from "../../../svg/double-shape"

const StyledInfiniteSlide = styled(InfiniteSlide)`
  position: absolute;
  top: -20rem;
  left: 0;
  transform: rotate(-20deg);
  z-index: -1;

  svg {
    margin-right: 15rem;
  }
`

const StyledInfiniteSlideBottom = styled(InfiniteSlide)`
  position: absolute;
  top: 170rem;
  left: 0;
  transform: rotate(10deg);
  z-index: -1;

  svg {
    margin-right: 15rem;
  }
`

const StyledDoubleShape = styled(DoubleShape)`
  position: absolute;
  top: auto !important;
  right: -15rem;
  bottom: -65rem;
  left: auto !important;
  transform: rotate(-10deg);
  z-index: -1;
`
const AboutContent = ({ aboutUsPageSlug, homepageData }) => {
  return (
    <>
      <StyledInfiniteSlide>
        <Blog />
        <Blog />
        <Blog />
      </StyledInfiniteSlide>
      <StyledInfiniteSlideBottom>
        <Blog />
        <Blog />
        <Blog />
      </StyledInfiniteSlideBottom>
      <StyledDoubleShape
        firstColor={Theme.colors.second}
        secondColor={Theme.colors.main}
        isAnimated={true}
      />
    </>
  )
}

AboutContent.propTypes = {
  homepageData: PropTypes.object,
  aboutUsPageSlug: PropTypes.string,
}

export default AboutContent
