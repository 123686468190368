import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import { useInView } from "react-cool-inview"

const AnimationContainer = styled.div`
  @media (min-width: ${props => props.theme.devices.desktop}) {
    position: relative;
    top: 5rem;
    transition:
      top 1000ms ${props => props.theme.easing},
      opacity 1000ms ${props => props.theme.easing};
    opacity: 0;

    ${props =>
      props.inView &&
      `
    top:0;
    opacity: 1;
  `}
  }
`

const ShowAnimation = ({ children }) => {
  const { observe, inView } = useInView({
    threshold: 0.5,
    unobserveOnEnter: true,
  })

  return (
    <AnimationContainer ref={observe} inView={inView}>
      {children}
    </AnimationContainer>
  )
}

ShowAnimation.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
}

export default ShowAnimation
